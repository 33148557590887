/* eslint-disable */
import { Options, Vue } from 'vue-class-component'
import { Action, Getter, Mutation, State } from 'vuex-class'
import { BDSM } from '@/store/bdsm/types';

const namespace: string = 'bdsm';
@Options({
	computed: {
		pages() {
			this.allPages = Math.ceil(this.getBDSMVideo.length / this.videoPerPage);
			return this.allPages;
		},
		paginatedUsers() {
			let from = (this.pageNumber - 1) *  this.videoPerPage;
			let to = from + this.videoPerPage;
			return this.getBDSMVideo.slice(from, to);
		},
		nextPage() {
			if(this.pageNumber <= this.allPages) this.pageNumber++;
		},
		setHandlePage() {
			if(this.handlePage <= this.allPages) this.pageNumber = this.handlePage;
		}
	}
})

export default class BdsmShowArchivePage extends Vue {
	@State('bdsm') bdsm: BDSM | undefined;
	@Action('storeModelTodo', {namespace}) storeModelTodo: any;
	@Action('storeCameraPosition', {namespace}) storeCameraPosition: any;
	@Action('fetchBDSMVideo', {namespace}) fetchBDSMVideo: any;
	@Action('storeDonate', {namespace}) storeDonate: any;
	@Action('storeBDSMViewing', {namespace}) storeBDSMViewing: any;
	@Getter('getTimeLeft', {namespace}) getTimeLeft: any;
	@Getter('getBDSMVideo', {namespace}) getBDSMVideo: any;
	@Mutation('setTime', {namespace}) setTime: any;

	allPages: number = 0;
	videoPerPage: number = 9;
	pageNumber: number = 1;
	handlePage: number = 1;

	pageClick(page: number) {
		this.pageNumber = page;
	}
	mounted() {
		this.fetchBDSMVideo();
	}
}
